<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <g-card page-title="Link Search" page-icon="mdi-search-web">
    <g-overlay :is-relay="isOverlay"/>
    <page-progress :is-loading="isLoading"/>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <v-form ref="searchForm" v-model="isSearchValid" lazy-validation>
          <g-p-search
              @applySearch="linkSearchHandler"
              hint="Minimum two characters needed to search(live link)."
          />
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
            :headers="headers"
            :items="invoices"
            class="elevation-1 mt-0"
            dense
        >
          <template v-slot:no-results>
            {{noResultsText}}
          </template>
          <template v-slot:no-data>
            {{noResultsText}}
          </template>
          <template v-slot:item.paymentStatus="{ item }">
            <g-payment-status-chip v-if="item.paymentStatus === 100"/>
            <g-payment-status-chip v-else-if="item.paymentStatus === 200" color="green" text="Paid"/>
            <g-payment-status-chip v-else-if="item.paymentStatus === 300" color="lime" text="Pending"/>
            <g-payment-status-chip v-else-if="item.paymentStatus === 400" color="green darken-4" text="Refund"/>
            <g-payment-status-chip v-else-if="item.paymentStatus === 600" color="orange" text="Republish"/>
          </template>
          <template v-slot:item.totalAmount="{item}">
            ${{item.totalAmount}}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </g-card>
</template>

<script>
import PageProgress from "../components/PageProgress";
import GPSearch from "../components/GPSearch";
import LinkSearch from "../services/LinkSearch";
import GOverlay from "../components/GOverlay";
import GPaymentStatusChip from "../components/GPaymentStatusChip";
import GCard from "../components/GCard";
export default {
  name: "LinkSearch",
  components: {
    GCard,
    GPaymentStatusChip,
    GOverlay,
    GPSearch,
    PageProgress,
  },
  data() {
    return {
      isLoading: false,
      isSearchValid: true,
      invoices: [],
      isOverlay: false,
      headers: [
        {
          text: 'Invoice No.',
          value: 'invoiceNo'
        },
        {
          text: 'Order ID',
          value: 'orderId'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Status',
          value: 'paymentStatus'
        },
        {
          text: 'Total Amount',
          value: 'totalAmount',
          align: 'right'
        },
      ],
      noResultsText: "Please type or paste a link on search box.",
    }
  },
  methods: {
    linkSearchHandler(item) {
      this.invoices = [];
      this.noResultsText = "Please type or paste a link on search box.";
      if(!item) return;
      this.isOverlay = true;
      LinkSearch.searchLink({postLink: item})
          .then((response) => {
            this.invoices = response.data.data.items;
            this.noResultsText = "No data available for this search link.";
            this.isOverlay = false;
          })
          .catch((error) => {
            this.isOverlay = false;
          })
    }
  }
}
</script>

<style scoped>

</style>